<template>
	<v-row no-gutters>
		<v-col cols="12">
			<v-tabs v-model="tab" grow>
				<v-tab>Conexões Banco</v-tab>
				<v-tab>Análise de Desconto</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<iframe
								title="Conexões banco"
								style="height:80vh; width:100%"
								src="https://app.powerbi.com/reportEmbed?reportId=ea116a1f-bb64-4c03-99ee-dde3dd9a141c&autoAuth=true&ctid=ea3d1bd3-63c2-4015-bc1d-5d4ae27100cd"
								frameborder="0"
								allowfullscreen="true"
							></iframe>
						</v-card-text>
					</v-card>
				</v-tab-item>
				<v-tab-item>
					<v-card flat>
						<v-card-text>
                            <iframe
                                title="Report Section"
                                style="height:80vh; width:100%"
                                src="https://app.powerbi.com/view?r=eyJrIjoiMzBjMmUzNGUtYzc5Ni00ZDk3LWI1YWUtYmEwZmMxZjkyZDcyIiwidCI6ImM0ZTVmMWUyLWIyNTktNDE3NC04NTIyLWM0OGZiNmI5ZTAzNyJ9"
                                frameborder="0"
                                allowFullScreen="true">
                            </iframe>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "TiBI",
	components: {},
	data: () => ({
		tab: 0,
	}),
	computed: {},
	methods: {
		async init() {},
	},
	created() {
		this.init();
	},
};
</script>